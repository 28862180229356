import React from "react"
import { animateScroll as scroll } from "react-scroll"
import { Link } from "gatsby"

import FeatherIcon from "feather-icons-react"
import GuidoLogo from "./guido-logo"

const footerLinkClassName =
  "text-white text-base hover:text-cyan cursor-pointer"

const Footer = ({ text }) => {
  return (
    <footer className="w-full flex flex-col">
      <div className="w-full bg-light-purple flex justify-center">
        <div className="container flex justify-between items-center flex-wrap relative">
          <button
            className="button button--secondary w-12 px-0 absolute right-0 top-0 mr-4 -m-4 xl:mr-0"
            onClick={() => scroll.scrollToTop()}
          >
            <FeatherIcon icon="chevron-up" />
          </button>
          <div className="mt-8">
            <Link to="/">
              <GuidoLogo className="w-auto h-8 mb-5" />
            </Link>
            <p className="text-white pb-10 pr-4">{text}</p>
          </div>
          <ul className="self-end pb-10">
            <li className={`${footerLinkClassName}`}>
            <Link to="/impressum/" className="no-underline">Impressum</Link>
            </li>
            <li className={`${footerLinkClassName}`}>
            <Link to="/datenschutz/" className="no-underline" >Datenschutz </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-full h-10 flex justify-center items-center bg-dark-blue">
        <div className="container">
          <p className="text-white">
            Guido – ein Produkt von{" "}
            <a
              href="https://voll.digital"
              target="_blank"
              rel="noopener noreferrer"
            >
              VOLL DIGITAL
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
