import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Footer from "./footer"
import "../layout.css"
import Helmet from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      contentfulHomepage {
        startText {
          startText
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet
        meta={[
          {
            name: "robots",
            content: "noindex, nofollow",
          },
        ]}
        link={[
          {
            type: "text/css",
            rel: "stylesheet",
            href:
              "https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Oxygen:wght@400;700&display=swap",
          },
        ]}
      >
        <html lang="de" />>
      </Helmet>

      <main>{children}</main>

      <Footer text={data.contentfulHomepage.startText.startText} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
