import React from "react"

const GuidoLogo = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="274.92"
      height="64.11"
      viewBox="0 0 274.92 64.11"
    >
      <linearGradient
        id="a"
        x1="12.44"
        x2="59.5"
        y1="57.33"
        y2="-1.49"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#e739d8" />
        <stop offset=".11" stopColor="#d156de" />
        <stop offset=".33" stopColor="#ab86e9" />
        <stop offset=".53" stopColor="#8eacf1" />
        <stop offset=".71" stopColor="#79c7f7" />
        <stop offset=".87" stopColor="#6cd7fb" />
        <stop offset="1" stopColor="#68ddfc" />
        <stop offset="1" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="13.14"
        x2="60.2"
        y1="57.98"
        y2="-.85"
        xlinkHref="#a"
      />
      <path
        fill="url(#a)"
        d="M32 22.12h19.84a3.75 3.75 0 000-7.5H32a17.43 17.43 0 00-17.43 18.27 17.6 17.6 0 0017.79 16.59h7.56a3.75 3.75 0 000-7.5h-7.56a10.23 10.23 0 01-10.3-9.44A9.93 9.93 0 0132 22.12z"
      />
      <path
        fill="url(#b)"
        d="M54.81 29.18a3.71 3.71 0 00-2.39-.88H32.05a3.75 3.75 0 100 7.5h17.09V53a3.57 3.57 0 01-3.57 3.57H32.05A24.56 24.56 0 017.51 31.36C7.87 18.2 19.14 7.5 32.64 7.5h18.72a3.75 3.75 0 000-7.5H32.64C15.12 0 .48 14 0 31.16a32.07 32.07 0 0032 33h13.57A11.09 11.09 0 0056.64 53V32.39a3.75 3.75 0 00-1.83-3.21z"
      />
      <g fill="#fff">
        <path d="M160.72 7.66a3.27 3.27 0 00-3.27 3.27v29.4a10 10 0 11-19.94 0v-29.4a3.27 3.27 0 10-6.54 0v29.4a16.51 16.51 0 0033 0v-29.4a3.28 3.28 0 00-3.25-3.27zM106.79 14.2h13.15a3.27 3.27 0 000-6.54h-13.15a24 24 0 000 48h13.15a3.26 3.26 0 003.27-3.27V31.65a3.27 3.27 0 00-3.27-3.27h-13.27a3.27 3.27 0 100 6.54h10v14.19h-9.88a17.46 17.46 0 010-34.91zM203.2 7.66h-12a3.27 3.27 0 00-3.2 3.27v41.45a3.26 3.26 0 003.27 3.27h12a24 24 0 100-48zm0 41.45h-8.69V14.2h8.69a17.46 17.46 0 110 34.91zM175.53 7.66a3.27 3.27 0 00-3.27 3.27v41.45a3.27 3.27 0 006.54 0V10.93a3.28 3.28 0 00-3.27-3.27zM253.76 6.56h-1.29a21.19 21.19 0 00-21.16 21.16v8.39a21.19 21.19 0 0021.16 21.16h1.29a21.18 21.18 0 0021.16-21.16v-8.39a21.18 21.18 0 00-21.16-21.16zm14.62 29.55a14.64 14.64 0 01-14.62 14.62h-1.29a14.64 14.64 0 01-14.62-14.62v-8.39a14.64 14.64 0 0114.62-14.62h1.29a14.64 14.64 0 0114.62 14.62z" />
      </g>
    </svg>
  )
}

export default GuidoLogo
