import React from "react"
import PropTypes from "prop-types"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { Link } from "gatsby"

import GuidoLogo from "./guido-logo"
import Layout from "./layout"
import SEO from "./seo"

const StaticPage = ({ content, title }) => {
  const options = {
    renderText: text =>
      text.split("\n").flatMap((text, i) => [i > 0 && <br />, text]),
  }

  return (
    <Layout>
      <SEO title={`${title} – GUIDO`}/>
      <div className="content bg-gradient-indigo-dark-purple flex-col items-center">
        <div className="container">
          <Link to="/">
            <GuidoLogo className="w-auto h-8 mb-12" />
          </Link>
          <h1 className="title text-white">{title}</h1>
        </div>
      </div>
      <section className="children content static-page__content">
        <div className="container">
          {documentToReactComponents(content, options)}
        </div>
      </section>
    </Layout>
  )
}

StaticPage.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StaticPage
